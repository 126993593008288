
import {Injectable } from '@angular/core'

@Injectable()
export class ApiConstant
{
    // public Server: string = 'http://localhost:8080/';
    // public Server2: string = 'http://localhost:8080/';

    // public Server: string = 'http://192.168.29.30:8080/v1/';
    // public Server2: string = 'http://192.168.29.30:8080/';

    // public Server: string = 'https://development.meeko.world/api/v1/';
    // public Server2: string = 'https://development.meeko.world/api/';
    // public url: string = 'https://development.meeko.world/';

    public Server: string = 'https://www.meeko.world/api/v1/';
    public Server2: string = 'https://www.meeko.world/api/';
    public url: string = 'https://www.meeko.world/';

    public badgeLevelELearnerId = "610a51918e2f742bc8662551";
    public badgeLevelEducatorId = "610a51978e2f742bc8662552";

    public ServerWithApiUrl = this.Server;

    public crypto_password: string = 'XtjDjBL2Q5QIX1GVIzJk2nItU29RnTti';

    public pagination = 10;
}
