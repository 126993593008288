<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Add Beneficiary</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 search-addbtn">
                <div class="input-group search-area d-xl-inline-flex d-none">
                    <input type="text" class="form-control" id="search" placeholder="Search here...">
                    <div class="input-group-append">
                        <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                    </div>
                </div>
                <div class="ml-3">
                    <button [disabled]="addbtn" class="btn btn-outline-primary" (click)="add()">Add Beneficiary</button>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3"></th>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Code</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Name</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Add 1</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Add 2</th>
                            <th scope="col" class="text-left px-1 py-2">Bene City</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Pin</th>
                            <th scope="col" class="text-left px-1 py-2">State</th>
                            <th scope="col" class="text-left px-1 py-2">Country</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Email</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Mobile</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Tel</th>
                            <th scope="col" class="text-left px-1 py-2">Bene Fax</th>
                            <th scope="col" class="text-left px-1 py-2">IFSC</th>
                            <th scope="col" class="text-left px-1 py-2">Bene A/c No</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let beneficiary of beneficiaryList; index as i">
                            <td><input type="checkbox" [(ngModel)]="beneficiary.selected" (change)="addbeneficiary(beneficiary.selected, beneficiary)"></td>
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ beneficiary.bankDetail.code }}</td>
                            <td>{{ beneficiary.bankDetail.holderName }}</td>
                            <td>{{ beneficiary.bankDetail.address1 }}</td>
                            <td>{{ beneficiary.bankDetail.address2 }}</td>
                            <td>{{ beneficiary.bankDetail.city }}</td>
                            <td>{{ beneficiary.bankDetail.pincode }}</td>
                            <td>{{ beneficiary.bankDetail?.state?.value }}</td>
                            <td>{{ beneficiary.bankDetail.country }}</td>
                            <td>{{ beneficiary.bankDetail.email }}</td>
                            <td>{{ beneficiary.bankDetail.mobile }}</td>
                            <td>{{ beneficiary.bankDetail.telNo }}</td>
                            <td>{{ beneficiary.bankDetail.faxNo }}</td>
                            <td>{{ beneficiary.bankDetail.IFSCCode }}</td>
                            <td>{{ beneficiary.bankDetail.accountNo }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div> -->
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->
